import { empty } from "glamor";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GetUser } from "store/slices/authSlice";
import { ActivePlanApi, EmptySmsAlertApi, FeatureAccessApi, MailChimpSubscriptionApi } from "store/slices/salonSlice";
import { usermoduleListViewApi, usermoduleAccessViewApi } from "../store/slices/usermoduleSlice";
import { OpenAddClientForm } from "../store/slices/clientSlice";
import { notificationCountApi } from "store/slices/notificationSlice";
import { OpenSmsRunOutAlerts } from "store/slices/calendarSlice";
import { useAuth } from "context/authContext";

const SalonModule = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { getAuthUser } = useAuth();
  const usermodule = useSelector((state) => state.usermodule.isListView);
  const isFeatureAccess = useSelector((state) => state.salon.isFeatureAccess);
  const isActivePlan = useSelector((state) => state.salon.isActivePlan);
  const isSmsAlertData = useSelector((state) => state.salon.isSmsAlertData);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth && auth.user;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuthUser = async () => {
      try {
        const userData = await getAuthUser();
        if(userData){
          dispatch({
            type: "auth/user",
            payload: {
              ...auth, 
              user: {
                ...auth.user,  // Keep the current user data
                ...userData,
              }
            }
          });
        }
      } catch (error) {
        console.error("Error fetching auth user:", error);
      }
    };
    fetchAuthUser(); // Call getAuthUser function here
  }, []);
 
  if (currentUser && currentUser.role_id == 8 && ["/dashboard", "/staff", "/reports"].includes(location && location.pathname)) {
    navigate("/noaccess");
  }
  
  useEffect(() => {
    // dispatch(usermoduleListViewApi());
    if (usermodule.length === 0) {
      dispatch(usermoduleListViewApi()).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          //dispatch(salonpermission({ module_id: 6, permissonName: "list" }));
        }
      });
      dispatch(usermoduleListViewApi({ type: "staff_access" }));
    }
    if (currentUser && currentUser.isOwner === 1) {
      dispatch(notificationCountApi());
      // if ((location && location.pathname === "/dashboard") || (location && location.pathname === "/calendar") || (location && location.pathname === "/settings")) {
      //   // dispatch(GetUser());
      // }
    }else{
      // dispatch(GetUser());
      dispatch(notificationCountApi());
    }
  }, [location, usermodule.length]);
  
  useEffect(() => {
    dispatch(OpenAddClientForm(""));
    dispatch(MailChimpSubscriptionApi({ id:currentUser && currentUser.salon && currentUser.salon.id ? currentUser.salon.id : "", salon_id:currentUser && currentUser.salon && currentUser.salon.id }));
  }, [location]);

  useEffect(() => {
    // dispatch(EmptySmsAlertApi())
    if(currentUser && currentUser.salon && currentUser.salon.is_empty_sms_alert == 1){
      dispatch(OpenSmsRunOutAlerts("open"));
    }
  }, [])
  
};

export { SalonModule };
