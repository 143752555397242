import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";
import { format } from "crypto-js";
import moment from "moment";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["working_hours", "StaffUserPermission"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/staff/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const update = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["working_hours", "StaffUserPermission"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/staff/update/" + values.id;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const calendarresourceandstaff = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const next_page_url = values && values.next_page_url;
  // const result = values && values.result ? values.result : "";
  // const staff_id = values && values.staff_id ? values.staff_id : "";
  // const resource_id = values && values.resource_id ? values.resource_id : "";
  // const resource_item_id = values && values.resource_item_id ? values.resource_item_id : "";
  // const type = values && values.type ? values.type : "";
  // const pagetype = values && values.pagetype ? values.pagetype : "";
  // const getRow = values && values.getRow ? values.getRow : "";
  // const calendarTab = values && values.calendarTab ? values.calendarTab : "";
  
  // let resourcedata;
  // resourcedata = {
  //   id: "",
  //   pagetype: pagetype,
  //   getRow: getRow,
  //   page_name: values && values.page_name ? values.page_name : "",
  //   singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : "",
  //   auth_key: auth_key,
  //   action: action,
  //   pagination: false,
  //   staff_id: staff_id,
  //   resource_id: resource_id,
  //   resource_item_id: resource_item_id,
  //   calendarTab: calendarTab,
  //   type: type,
  //   result: result,
  // };
  // const data = resourcedata;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/staff/calendarstaffresource";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, formData, { headers: authHeader() });
};

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const dropdown = values && values.dropdown ? values.dropdown : "";
  const service_id = values && values.service_id ? values.service_id : "";
  const pagetype = values && values.pagetype ? values.pagetype : "";
  const getRow = values && values.getRow ? values.getRow : "";
  const availebleTimeObj = values && values.availebleTimeObj ? values.availebleTimeObj : "";
  const formName = values && values.formName ? values.formName : "";

  let sortstring = "";
  if (sort) {
    let sortArray = [];
    let sortSubArray = [];
    Object.keys(sort).map(function (key, index) {
      if (Object.keys(sort[key]).length > 0) {
        Object.keys(sort[key]).map(function (subkey, subindex) {
          sortSubArray[subindex] = `sort[${key}.${subkey}]=${sort[key][subkey]}`;
        });
      }
      if (key != "supplier") {
        sortArray[index] = `sort[${key}]=${sort[key]}`;
      }
    });
    if (sortSubArray.length > 0) {
      let jsubsort = sortSubArray.join("&");
      sortstring = jsubsort;
    }
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
    if (values.maxstaffAlert) {
      pagination = false;
    }
    if (values.staff_paginate) {
      pagination = true;
    }
  }
  const action = page ? `afterlogin/staff/view?page=${page}&${sortstring}` : `afterlogin/staff/view?${sortstring}`;
  let staffdata;
  if (dropdown) {
    staffdata = {
      auth_key: auth_key,
      action: action,
      pagination: false, //true or false
      id: "",
      result: result, //business_name,owner_name
      pagetype: pagetype,
      getRow: getRow,
      option: values && values.option ? values.option : "",
      page_name: values && values.page_name ? values.page_name : "",
      singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : "",
      availebleTimeObj,
      formName
    };
  } else {
    staffdata = {
      auth_key: auth_key,
      action: action,
      pagination: pagination, //true or false
      id: values && values.id ? values.id : "",
      result: result, //business_name,owner_name
      option: values && values.option ? values.option : "",
      service_id: service_id,
      pagetype: pagetype,
      page_name: values && values.page_name ? values.page_name : "",
      singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : "",
      availebleTimeObj,
      formName
    };
  }
  const data = staffdata;
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const deleted = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/staff/delete/${values.id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const suggetionlist = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let q = values && values.q ? values.q : "";
  const action = page ? `afterlogin/staff/view?page=${page}&q=${q}` : `afterlogin/staff/view?q=${q}`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: true, //true or false
    id: values && values.id ? values.id : "",
    apicall: "suggetion",
  };
  return axios.post(next_page_url ? `${next_page_url}&q=${q}` : API_URL + action, data, { headers: authHeader() });
};

const addonservices = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let staff_id = values && values.staff_id ? values.staff_id : "";
  const action = page ? `afterlogin/staff/addonservices?page=${page}&staff_id=${staff_id}` : `afterlogin/staff/addonservices?staff_id=${staff_id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const staffavailabletime = (values) => {
  console.log('valuesOFEDITS: ', values);
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  // const formData = new FormData();
  // for (let value in values) {
  //   if (["dateof"].includes(value) && values[value]) {
  //     formData.append(value, moment(values[value], "dddd, DD MMMM YYYY").format("YYYY-MM-DD"));
  //   } else {
  //     formData.append(value, values[value]);
  //   }
  // }
  // const action = "afterlogin/staff/availabletime";
  // formData.append("auth_key", auth_key);
  // formData.append("action", action);
  // return axios.post(API_URL + action, formData, { headers: authHeader() });
  const listview = values && values.listview ? values.listview : "";
  const appointment_id = values && values.id ? values.id : "";
  const busytime_id = "";
  const service_id = values && values.service_id ? values.service_id : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const prev_start_datetime = values && values.prev_start_datetime ? values.prev_start_datetime : "";
  let dateof = values && values.start_datetime ? values.start_datetime : "";
  if (!dateof) {
    let check_start_time = moment().format("HH:mm:ss");
    const isValidDate = values.dateof ? moment(values.dateof, "dddd, DD MMMM YYYY", true).isValid() : false;
    
    if (isValidDate) {
      if (prev_start_datetime) {
        const isSameDate = moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") === moment(prev_start_datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
        const isGreaterCurrentDate = moment().format("YYYY-MM-DD HH:mm:ss") < moment(prev_start_datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
        check_start_time = isSameDate && !isGreaterCurrentDate ? moment().format("HH:mm:ss") : "00:00:00";
        dateof = moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + check_start_time
      } else {
        let check_start_time = moment().format("HH:mm:ss");
        const isSameDate = moment().format("YYYY-MM-DD") === moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD");
        const isGreaterCurrentDate = moment().format("YYYY-MM-DD HH:mm:ss") < moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD HH:mm:ss");
        if (isSameDate && isGreaterCurrentDate) {
          check_start_time = moment(values.dateof, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
        } else if (isSameDate && !isGreaterCurrentDate) {
          // check_start_time = moment().format("HH:mm:ss");
          check_start_time = "00:00:00";
        } else {
          check_start_time = "00:00:00";
        }
        dateof = moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + check_start_time
      }
    }
  } else {
    let check_start_time = moment().format("HH:mm:ss");
    const isValidDate = dateof ? moment(dateof, "YYYY-MM-DD HH:mm:ss", true).isValid() : false;
    if (isValidDate) {
      const isSameDate = moment().format("YYYY-MM-DD") === moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
      const isGreaterCurrentDate = moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") > moment().format("YYYY-MM-DD");
      // const isGreaterCurrentDate = moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") > moment().format("YYYY-MM-DD HH:mm:ss");
      if (isSameDate && isGreaterCurrentDate) {
        check_start_time = moment(dateof, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
      } else if (isSameDate && !isGreaterCurrentDate) {
        // check_start_time = moment().format("HH:mm:ss");
        check_start_time = "00:00:00";
      } else {
        check_start_time = "00:00:00";
      }
      dateof = moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") + " " + check_start_time;
      values.start_datetime = moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") + " " + check_start_time;
    }
  }
  
  const uid = values && values.uid ? values.uid : "1";
  const apicall = values && values.apicall ? values.apicall : "staff";
  const salon_id = auth && auth.user ? auth.user.salon_id : "";
  const duration = values && values.duration ? values.duration : "";
  const padding_time = values && values.padding_time ? values.padding_time : "";
  const durationWithPadding = values && values.durationWithPadding ? values.durationWithPadding : "";
  const parentAppointmentEndTime = values && values.parentAppointmentEndTime ? values.parentAppointmentEndTime : "";
  const roster_time = values && values.roster_time ? values.roster_time : "";
  const formtype = values && values.formtype ? values.formtype : "";
  const resource_item_id = values && values.resource_item_id ? values.resource_item_id : "";
  const start_datetime = values && values.start_datetime ? values.start_datetime : (dateof ? dateof : "");
  const isDurationCheck = values && values.isDurationCheck ? values.isDurationCheck : "";
  const totalDurationWithPadding = values && values.totalDurationWithPadding ? values.totalDurationWithPadding : "";
  const formName = values && values.formName ? values.formName : "";
  // const action = `widget/staff/availabletime`;
  const action = `afterlogin/appointment/nextresourceitemtime`;
  const data = {
    action: action,
    remove_addon : values?.remove_addon || [],
    remove_addanother : values?.remove_addanother || [],
    service_id: service_id,
    staff_id: staff_id,
    dateof: dateof,
    uid: uid,
    apicall: apicall,
    salon_id: salon_id,
    duration: duration,
    padding_time: padding_time,
    durationWithPadding: durationWithPadding,
    totalDurationWithPadding: totalDurationWithPadding,
    parentAppointmentEndTime: parentAppointmentEndTime,
    listview: listview,
    roster_time: roster_time,
    appointment_id: listview == "Appointment" ? appointment_id : "",
    busytime_id: listview == "BusyTime" ? busytime_id : "",
    formtype: formtype,
    isDurationCheck: isDurationCheck,
    resource_item_id: resource_item_id,
    start_datetime,
    prev_start_datetime,
    formName
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const staffbusytime = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const listview = values && values.listview ? values.listview : "";
  const busytime_id = values && values.id ? values.id : "";
  const appointment_id = "";
  const service_id = values && values.service_id ? values.service_id : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const prev_start_datetime = values && values.prev_start_datetime ? values.prev_start_datetime : "";
  let dateof = values && values.start_datetime ? values.start_datetime : "";
  if (!dateof) {
    let check_start_time = moment().format("HH:mm:ss");
    const isValidDate = values.dateof ? moment(values.dateof, "dddd, DD MMMM YYYY", true).isValid() : false;
    if (isValidDate) {
      if (prev_start_datetime) {
        const isSameDate = moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") === moment(prev_start_datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
        const isGreaterCurrentDate = moment().format("YYYY-MM-DD HH:mm:ss") < moment(prev_start_datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
        check_start_time = isSameDate && !isGreaterCurrentDate ? moment().format("HH:mm:ss") : "00:00:00";
        dateof = moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + check_start_time
      } else {
        let check_start_time = moment().format("HH:mm:ss");
        const isSameDate = moment().format("YYYY-MM-DD") === moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD");
        const isGreaterCurrentDate = moment().format("YYYY-MM-DD HH:mm:ss") < moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD HH:mm:ss");
        if (isSameDate && isGreaterCurrentDate) {
          check_start_time = moment(values.dateof, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
        } else if (isSameDate && !isGreaterCurrentDate) {
          check_start_time = moment().format("HH:mm:ss");
        } else {
          check_start_time = "00:00:00";
        }
        dateof = moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + check_start_time
      }
    }
  } else {
    let check_start_time = moment().format("HH:mm:ss");
    const isValidDate = dateof ? moment(dateof, "YYYY-MM-DD HH:mm:ss", true).isValid() : false;
    if (isValidDate) {
      const isSameDate = moment().format("YYYY-MM-DD") === moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
      const isGreaterCurrentDate = moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") > moment().format("YYYY-MM-DD HH:mm:ss");
      if (isSameDate && isGreaterCurrentDate) {
        check_start_time = moment(dateof, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
      } else if (isSameDate && !isGreaterCurrentDate) {
        check_start_time = moment().format("HH:mm:ss");
      } else {
        check_start_time = "00:00:00";
      }
      dateof = moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") + " " + check_start_time;
    }
  }
  const uid = values && values.uid ? values.uid : "1";
  const apicall = values && values.apicall ? values.apicall : "staff";
  const salon_id = auth && auth.user ? auth.user.salon_id : "";
  const duration = values && values.duration ? values.duration : "";
  const padding_time = values && values.padding_time ? values.padding_time : "";
  const durationWithPadding = values && values.durationWithPadding ? values.durationWithPadding : "";
  const parentAppointmentEndTime = values && values.parentAppointmentEndTime ? values.parentAppointmentEndTime : "";
  const roster_time = values && values.roster_time ? values.roster_time : "";
  const formtype = values && values.formtype ? values.formtype : "";
  const resource_item_id = values && values.resource_item_id ? values.resource_item_id : "";
  const start_datetime = values && values.start_datetime ? values.start_datetime : (dateof ? dateof : "");
  const isDurationCheck = values && values.isDurationCheck ? values.isDurationCheck : "";
  const formName = values && values.formName ? values.formName : "";
  const action = `widget/staff/availabletime`;
  // const action = `afterlogin/appointment/nextresourceitemtime`;
  const data = {
    action: action,
    service_id: service_id,
    staff_id: staff_id,
    dateof: dateof,
    uid: uid,
    apicall: apicall,
    salon_id: salon_id,
    duration: duration,
    padding_time: padding_time,
    durationWithPadding: durationWithPadding,
    parentAppointmentEndTime: parentAppointmentEndTime,
    listview: listview,
    roster_time: roster_time,
    appointment_id: listview == "Appointment" ? appointment_id : "",
    busytime_id: listview == "BusyTime" ? busytime_id : "",
    formtype: formtype,
    isDurationCheck: isDurationCheck,
    resource_item_id: resource_item_id,
    start_datetime,
    prev_start_datetime,
    formName
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const staffworkinghours = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/staff/staffworkinghours`;
  //Working hour Calender view parameter
  const start_date = values && values.start_date ? moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const end_date = values && values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const timezone = values && values.timezone ? values.timezone : "";
  const type = values && values.type ? values.type : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const showdate = values && values.showdate ? moment(values.showdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showstartdate = values && values.showstartdate ? moment(values.showstartdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showenddate = values && values.showenddate ? moment(values.showenddate).format("YYYY-MM-DD HH:mm:ss") : "";

  const data = {
    auth_key: auth_key,
    action: action,
    id: values && values.id ? values.id : "",
    start_date: start_date,
    end_date: end_date,
    timezone: timezone,
    type: type,
    staff_id: staff_id,
    showdate: showdate,
    showstartdate: showstartdate,
    showenddate: showenddate,
    page_name: values && values.page_name ? values.page_name : "",
    singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const staffawayapi = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/staff/staffaway`;
  //Working hour Calender view parameter
  const start_date = values && values.start_date ? moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const end_date = values && values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const timezone = values && values.timezone ? values.timezone : "";
  const type = values && values.type ? values.type : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const formName = values && values.formName ? values.formName : "";
  const showdate = values && values.showdate ? moment(values.showdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showstartdate = values && values.showstartdate ? moment(values.showstartdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showenddate = values && values.showenddate ? moment(values.showenddate).format("YYYY-MM-DD HH:mm:ss") : "";

  const data = {
    auth_key: auth_key,
    action: action,
    id: values && values.id ? values.id : "",
    start_date: start_date,
    end_date: end_date,
    timezone: timezone,
    type: type,
    staff_id: staff_id,
    showdate: showdate,
    showstartdate: showstartdate,
    showenddate: showenddate,
    formName: formName,
    page_name: values && values.page_name ? values.page_name : "",
    singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const addonstaffoptions = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let service_id = values && values.service_id ? values.service_id : "";
  let add_on_service_id = values && values.add_on_service_id ? values.add_on_service_id : "";
  let staff_id = values && values.staff_id ? values.staff_id : "";
  let isNotId = values && values.isNotId ? values.isNotId : "";
  const action = page ? `afterlogin/staff/addonstaffoptions?page=${page}&isNotId=${isNotId}` : `afterlogin/staff/addonstaffoptions?isNotId=${isNotId}`;
  const data = {
    auth_key: auth_key,
    action: action,
    service_id: service_id,
    add_on_service_id: add_on_service_id,
    staff_id: staff_id,
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const staffaccesscreate = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["working_hours"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/staff/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const staffaccesslistview = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/staffaccess/view?page=${page}` : `afterlogin/staffaccess/view`;

  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination, //true or false
    id: values && values.id ? values.id : "",
    salon_id: values && values.client_id ? values.client_id : "",
    result: result, //business_name,owner_name
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const rosterstaff = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/staff/rosterstaff`;
  //Working hour Calender view parameter
  const start_date = values && values.start_date ? moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const end_date = values && values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const timezone = values && values.timezone ? values.timezone : "";
  const type = values && values.type ? values.type : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const showdate = values && values.showdate ? moment(values.showdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showstartdate = values && values.showstartdate ? moment(values.showstartdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showenddate = values && values.showenddate ? moment(values.showenddate).format("YYYY-MM-DD HH:mm:ss") : "";

  const data = {
    auth_key: auth_key,
    action: action,
    id: values && values.id ? values.id : "",
    start_date: start_date,
    end_date: end_date,
    timezone: timezone,
    type: type,
    staff_id: staff_id,
    showdate: showdate,
    showstartdate: showstartdate,
    showenddate: showenddate,
    page_name: values && values.page_name ? values.page_name : "",
    singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const staffcounts = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/staff/staffcount";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const staffApiController = {
  create,
  update,
  view,
  deleted,
  suggetionlist,
  addonservices,
  staffavailabletime,
  staffworkinghours,
  staffawayapi,
  addonstaffoptions,
  staffaccesscreate,
  staffaccesslistview,
  rosterstaff,
  calendarresourceandstaff,
  staffbusytime,
  staffcounts
};
export default staffApiController;
