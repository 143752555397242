import axios from 'axios';
import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import config from "../config";

const AuthContext = createContext();
const API_URL = config.API_URL;

export const AuthProvider = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const getAuthUser = async () => {
    try {
      const auth_key = auth.user.auth_key;
      const token = auth.token;
      const action = `afterlogin/user`;
      const response = await axios.post(
        `${API_URL}${action}`, 
        { auth_key },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  };
  return (
    <AuthContext.Provider value={{ getAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
